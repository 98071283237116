@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 1px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  color: #2d3240;
  box-sizing: border-box;
}

code {
  font-family: 'Nunito', sans-serif;
}

.description {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width:400px) {
  .description {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

 * {
   box-sizing: border-box;
   /* color: #2d3240; */
   font-family: 'Nunito', sans-serif;
 }