section#contact-us {
  max-width: 85%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
}

section#contact-us .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

section#contact-us .row .column-one {
  display: flex;
  flex-direction: column;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 3rem;
  margin: 1rem auto;
}

/* input {
  border: none;
  outline: none;
  background-color: #f7f7f7;
  width: 100%;
  height: 45px;
  padding: 15px;
  border-radius: 3px;
  font-size: 14px;
} */

.map-location iframe {
  border: none;
  border-radius: 3px;
}

.map-location .heading {
  text-align: center;
  font-size: 2.2rem;
  margin: 1rem auto;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  section#contact-us {
    max-width: 100%;
    margin:auto 8px;
  }
}
