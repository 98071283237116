section#tours {
    width: 85%;
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:600px) {
    section#tours {
        width: 100%;
        margin: 0;
    }
}